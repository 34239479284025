<template>
  <div class="container-fluid inspection-global">
    <!--<div class="buttons-wrapper">
      <base-button
        :class="{
          active:
            selectedRequestStatus == null &&
            selectedRequestAssignedTo == null &&
            selectedRequestCreator == null,
        }"
        @click="changeSelectedStatus(null)"
      >
        {{ $t("REQUESTS.ALL") }}
      </base-button>
      <base-button
        :class="{ active: selectedRequestStatus == REQUEST_STATUS_DRAFT }"
        @click="() => changeSelectedStatus(REQUEST_STATUS_DRAFT)"
      >
        {{ $t("COMMON.DRAFTS") }}
      </base-button>
      <base-button
        :class="{
          active:
            selectedRequestStatus == REQUEST_STATUS_ASSIGNED &&
            selectedRequestAssignedTo != me.id,
        }"
        @click="() => changeSelectedStatus(REQUEST_STATUS_ASSIGNED)"
      >
        {{ $t("REQUESTS.ASSIGNED") }}
      </base-button>
      <base-button
        :class="{ active: selectedRequestStatus == REQUEST_STATUS_COMPLETED }"
        @click="changeSelectedStatus(REQUEST_STATUS_COMPLETED)"
      >
        {{ $t("REQUESTS.COMPLETED") }}
      </base-button>
      <base-button
        :class="{ active: selectedRequestStatus == REQUEST_STATUS_CANCELED }"
        @click="changeSelectedStatus(REQUEST_STATUS_CANCELED)"
      >
        {{ $t("REQUESTS.CANCELED") }}
      </base-button>
      <base-button
        :class="{
          active:
            selectedRequestStatus == REQUEST_STATUS_ASSIGNED &&
            selectedRequestAssignedTo == me.id,
        }"
        @click="changeSelectedStatus(REQUEST_STATUS_ASSIGNED, me.id)"
      >
        {{ $t("REQUESTS.ASSIGNED_TO_ME") }}
      </base-button>
      <base-button
        :class="{
          active: selectedRequestCreator == me.id,
        }"
        @click="changeSelectedStatus(null, null, me.id)"
      >
        {{ $t("REQUESTS.CREATED_BY_ME") }}
      </base-button>
    </div>-->

    <div class="page-title">
      <i class="fa-regular fa-arrows-retweet"></i>
      <h1>{{ $t("COMMON.REQUESTS") }}</h1>
    </div>

    <div class="list-table">
      <request-list-table
        :filterStatus="selectedRequestStatus"
        :filterAssignedTo="selectedRequestAssignedTo"
        :filterCreator="selectedRequestCreator"
        @onAddRequest="openRequestCreateModal"
        @onAddQuickRequest="openQuickRequestCreateModal"
        @onViewRequest="openRequestViewModal"
        @onEditRequest="openRequestEditModal"
        @onDeleteRequest="deleteRequest"
        :key="renderKey * 100"
      />
    </div>

    <transition name="slide-right">
      <div
        v-if="isViewRequestModalOpened"
        class="resizable-wrapper no-line"
        v-bind:class="[isViewRequestModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'REQUEST'"
          @onCloseModal="closeRequestModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("REQUESTS.VIEW_REQUEST") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-button
                v-if="canApproveRequest(openRequest)"
                type="info"
                icon
                size="sm"
                @click="approveRequest(openRequest)"
                :title="$t('REQUESTS.APPROVE_REQUEST')"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-check"></i>
                </span>
              </base-button>

              <base-button
                v-if="canAssignRequest(openRequest)"
                type="primary"
                icon
                size="sm"
                @click="
                  () => {
                    showAssignRequestModal = true;
                  }
                "
                :title="$t('REQUESTS.ASSIGN_REQUEST')"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-user"></i>
                </span>
              </base-button>

              <base-button
                v-if="canAddResponseObject(openRequest)"
                type="info"
                icon
                size="sm"
                @click="addResponseObject(openRequest)"
                :title="$t('REQUESTS.ADD_RESPONSE_OBJECT')"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-clipboard"></i>
                </span>
              </base-button>

              <base-button
                v-if="canCompleteRequest(openRequest)"
                type="success"
                icon
                size="sm"
                @click="completeRequest(openRequest)"
                :title="$t('REQUESTS.COMPLETE_REQUEST')"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-check"></i>
                </span>
              </base-button>

              <base-button
                v-if="!!openRequest.responseObject"
                type="info"
                icon
                size="sm"
                @click="showResponseObject(openRequest)"
                :title="$t('REQUESTS.SHOW_RESPONSE_OBJECT')"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-clipboard"></i>
                </span>
              </base-button>

              <base-button
                v-if="canCancelRequest()"
                type="warning"
                icon
                size="sm"
                @click="cancelRequest(openRequest)"
                :title="$t('REQUESTS.CANCEL_REQUEST')"
              >
                <span class="btn-inner--icon">
                  <i class="fa fa-window-close"></i>
                </span>
              </base-button>

              <notification-subscription
                v-if="openRequest"
                :objectType="'requests'"
                :objectId="openRequest.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openRequestEditModal(openRequest)"
                  v-if="
                    $currentUserCan($permissions.PERM_EDIT_REQUESTS) &&
                    openRequest.status === REQUEST_STATUS_DRAFT
                  "
                >
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button
                  class="delete"
                  @click="deleteRequest(openRequest)"
                  v-if="
                    $currentUserCan($permissions.PERM_DELETE_REQUESTS) &&
                    openRequest.status === REQUEST_STATUS_DRAFT
                  "
                >
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>

              <button class="expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>

              <button class="close" @click="closeRequestModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-request-component
              v-if="openRequest"
              :requestId="openRequest.id"
              @requestLoaded="
                (request) => {
                  openRequest = request;
                  openRequestLoaded = true;
                }
              "
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isEditRequestModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditRequestModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'REQUEST'"
          @onCloseModal="closeRequestModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("REQUESTS.EDIT_REQUEST") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="close" @click="openRequestViewModal(openRequest)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-request-component
              v-if="openRequest"
              :requestId="openRequest.id"
              @onViewRequest="openRequestViewModal"
            />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isAddRequestModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddRequestModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'REQUEST'"
          @onCloseModal="closeRequestModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("REQUESTS.ADD_REQUEST") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="expand" id="expand-button">
                <i class="fal fa-expand-arrows"></i>
              </button>
              <button class="close" @click="closeRequestModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-request-component @onViewRequest="openRequestViewModal" />
          </div>
        </div>
      </div>
    </transition>

    <transition name="slide-right">
      <div
        v-if="isAddQuickRequestModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddQuickRequestModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'REQUEST'"
          @onCloseModal="closeRequestModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>{{ $t("REQUESTS.ADD_QUICK_REQUEST") }}</h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeRequestModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-quick-request-component
              @onViewRequest="openRequestViewModal"
            />
          </div>
        </div>
      </div>
    </transition>

    <request-view-assign-request-form
      v-if="openRequest ? !!openRequest.id : false"
      :showModal="showAssignRequestModal"
      :request="openRequest"
      @onCloseAssignRequestModal="
        () => {
          showAssignRequestModal = false;
        }
      "
      @onRequestAssigned="
        () => {
          renderKey++;
        }
      "
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  REQUEST_STATUS_DRAFT,
  REQUEST_STATUS_APPROVED,
  REQUEST_STATUS_ASSIGNED,
  REQUEST_STATUS_COMPLETED,
  REQUEST_STATUS_CANCELED,
  REQUEST_TYPE_INSPECTIONS,
} from "@/constants/requests";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_ADD_QUICK_REQUEST,
} from "@/constants/common";
import {
  INSPECTION_STATUS_DRAFT,
  INSPECTION_STATUS_APPROVED,
  INSPECTION_STATUS_ASSIGNED,
} from "@/constants/inspections";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import RequestListTable from "./partials/RequestListTable.vue";
import EditRequestComponent from "./components/EditRequestComponent.vue";
import AddRequestComponent from "./components/AddRequestComponent.vue";
import AddQuickRequestComponent from "./components/AddQuickRequestComponent.vue";
import ViewRequestComponent from "./components/ViewRequestComponent.vue";
import RequestViewAssignRequestForm from "./partials/RequestViewAssignRequestForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    RequestListTable,
    NotificationSubscription,
    EditRequestComponent,
    AddRequestComponent,
    AddQuickRequestComponent,
    ViewRequestComponent,
    [Button.name]: Button,
    RequestViewAssignRequestForm,
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  watch: {},

  data() {
    const requestId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewRequestModalOpened = false;
    let isEditRequestModalOpened = false;
    let isAddRequestModalOpened = false;
    let isAddQuickRequestModalOpened = false;
    let openRequest = null;
    if (requestId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewRequestModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditRequestModalOpened = true;
      }
      openRequest = { id: requestId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddRequestModalOpened = true;
    } else if (action === QUERY_ACTIONS_ADD_QUICK_REQUEST) {
      isAddQuickRequestModalOpened = true;
    }
    return {
      isViewRequestModalOpened: isViewRequestModalOpened,
      isEditRequestModalOpened: isEditRequestModalOpened,
      isAddRequestModalOpened: isAddRequestModalOpened,
      isAddQuickRequestModalOpened: isAddQuickRequestModalOpened,
      openRequest: openRequest,
      openRequestLoaded: false,
      renderKey: 1,
      showAssignRequestModal: false,
      REQUEST_STATUS_DRAFT,
      REQUEST_STATUS_APPROVED,
      REQUEST_STATUS_ASSIGNED,
      REQUEST_STATUS_COMPLETED,
      REQUEST_STATUS_CANCELED,
      INSPECTION_STATUS_DRAFT,
      selectedRequestStatus: null,
      selectedRequestAssignedTo: null,
      selectedRequestCreator: null,
    };
  },

  methods: {
    openRequestCreateModal() {
      this.closeRequestModal();
      this.isAddRequestModalOpened = true;

      this.$router.replace({
        name: "List Requests",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },

    openQuickRequestCreateModal() {
      this.closeRequestModal();
      this.isAddQuickRequestModalOpened = true;

      this.$router.replace({
        name: "List Requests",
        query: { action: QUERY_ACTIONS_ADD_QUICK_REQUEST },
      });
    },

    openRequestViewModal(request, reRender = false) {
      this.closeRequestModal();
      this.openRequest = request;
      this.isViewRequestModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List Requests",
        query: { id: this.openRequest.id, action: QUERY_ACTIONS_VIEW },
      });
    },

    openRequestEditModal(request) {
      this.closeRequestModal();
      this.openRequest = request;
      this.isEditRequestModalOpened = true;

      this.$router.replace({
        name: "List Requests",
        query: { id: this.openRequest.id, action: QUERY_ACTIONS_EDIT },
      });
    },

    closeRequestModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      this.isAddRequestModalOpened = false;
      this.isAddQuickRequestModalOpened = false;
      this.isViewRequestModalOpened = false;
      this.isEditRequestModalOpened = false;
      this.openRequest = null;

      if (
        this.$route.name !== "List Requests" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List Requests",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    async deleteRequest(request) {
      const confirmation = await swal.fire({
        title: this.$t("REQUESTS.DELETE_THIS_REQUEST"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("requests/destroy", request.id);
          this.renderKey++;
          this.closeRequestModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("REQUESTS.REQUEST_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async cancelRequest(request) {
      const confirmation = await swal.fire({
        title: this.$t("REQUESTS.CANCEL_REQUEST"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.isConfirmed) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("requests/cancel", request.id);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REQUESTS.REQUEST_CANCELED"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async completeRequest(request) {
      const confirmation = await swal.fire({
        title: this.$t("REQUESTS.COMPLETE_REQUEST"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("requests/complete", {
            requestId: request.id,
            data: {},
          });
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("REQUESTS.REQUEST_COMPLETED"),
          });
          this.renderKey++;
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async approveRequest(request) {
      const confirmation = await swal.fire({
        title: this.$t("REQUESTS.APPROVE_REQUEST"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (confirmation.isConfirmed) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("requests/approve", {
          requestId: request.id,
          data: {},
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REQUESTS.REQUEST_APPROVED"),
        });
        this.renderKey++;
        swal.close();
      } catch (error) {
        swal.close();
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    changeSelectedStatus(status, assignedTo = null, creator = null) {
      this.selectedRequestStatus = status;
      this.selectedRequestAssignedTo = assignedTo;
      this.selectedRequestCreator = creator;
    },

    canCancelRequest(request) {
      if (!request) {
        return false;
      }
      if (!this.$currentUserCan(this.$permissions.PERM_CANCEL_REQUESTS)) {
        return false;
      }
      if (request.status === REQUEST_STATUS_COMPLETED) {
        return false;
      }
      if (request.status === REQUEST_STATUS_CANCELED) {
        return false;
      }
      if (request.status === REQUEST_STATUS_DRAFT) {
        return false;
      }
      return true;
    },

    canApproveRequest(request) {
      if (!request) {
        return false;
      }
      if (!this.$currentUserCan(this.$permissions.PERM_APPROVE_REQUESTS)) {
        return false;
      }
      if (request.status != REQUEST_STATUS_DRAFT) {
        return false;
      }
      return true;
    },

    canAssignRequest(request) {
      if (!request) {
        return false;
      }
      if (!this.$currentUserCan(this.$permissions.PERM_ASSIGN_REQUESTS)) {
        return false;
      }
      if (request.status != REQUEST_STATUS_APPROVED) {
        return false;
      }
      return true;
    },

    canAddResponseObject(request) {
      if (!request) {
        return false;
      }
      if (request.status != REQUEST_STATUS_ASSIGNED) {
        return false;
      }
      if (request.responseObject) {
        return false;
      }

      if (request.request_type === REQUEST_TYPE_INSPECTIONS) {
        if (this.$currentUserCan(this.$permissions.PERM_CREATE_INSPECTIONS)) {
          return true;
        }
      }

      return false;
    },

    canCompleteRequest(request) {
      if (!request) {
        return false;
      }
      if (!this.$currentUserCan(this.$permissions.PERM_COMPLETE_REQUESTS)) {
        return false;
      }
      if (request.status != REQUEST_STATUS_ASSIGNED) {
        return false;
      }
      if (!request.responseObject) {
        return false;
      }
      return true;
    },

    addResponseObject(request) {
      if (request.request_type === REQUEST_TYPE_INSPECTIONS) {
        this.addInspection(request);
      }
    },

    async addInspection(request) {
      await this.$store.dispatch("inspections/list", {
        filter: {
          request: request.id,
        },
      });
      const inspections = this.$store.getters["inspections/list"].filter(
        (item) =>
          [
            INSPECTION_STATUS_DRAFT,
            INSPECTION_STATUS_APPROVED,
            INSPECTION_STATUS_ASSIGNED,
          ].includes(item.status)
      );
      if (inspections.length > 0) {
        this.$router.push({
          name: "List Inspections",
          query: { id: inspections[0].id, action: QUERY_ACTIONS_VIEW },
        });
      } else {
        this.$router.push({
          name: "List Inspections",
          query: { request_id: request.id, action: QUERY_ACTIONS_ADD },
        });
      }
    },

    showResponseObject(request) {
      this.$router.push(this.$objectViewRoute(request.responseObject));
    },
  },
};
</script>
