var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"tabs-wrapper"},[_c('div',{staticClass:"tab-pane-inner"},[_c('div',{staticClass:"inspection-form"},[_c('div',{staticClass:"inspection-form-group width-1_2"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.request.organization.id,"filterable":true,"showAll":false},on:{"organizationChanged":(organizationId) => {
                    _vm.request.organization.id = organizationId;
                    _vm.onFormChanged();
                  }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.request.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.request.organization.id},on:{"locationsChanged":(locations) => {
                    _vm.request.allowedLocations = locations;
                    _vm.onFormChanged();
                  }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1),_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.PRIORITY'),"placeholder":_vm.$t('COMMON.PRIORITY')}},[_c('div',{staticClass:"radio-group"},_vm._l((_vm.requestPrioritiesOption),function(priority){return _c('base-radio',{key:priority,staticClass:"mb-3",class:{
                    'priority-low': priority == _vm.REQUEST_PRIORITY_LOW,
                    'priority-medium': priority == _vm.REQUEST_PRIORITY_MEDIUM,
                    'priority-high': priority == _vm.REQUEST_PRIORITY_HIGH,
                  },attrs:{"name":`${priority}`},model:{value:(_vm.selectedPriority),callback:function ($$v) {_vm.selectedPriority=$$v},expression:"selectedPriority"}},[_vm._v(" "+_vm._s(_vm.$t(`REQUESTS.REQUEST_PRIORITY_${priority}`))+" "),_c('span',[_c('i',{staticClass:"far fa-check"})])])}),1)]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.priority}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')}},[_c('div',{staticClass:"radio-group"},_vm._l((_vm.requestTypesOption),function(type){return _c('base-radio',{key:type,staticClass:"mb-3",attrs:{"name":`${type}`,"disabled":!_vm.$currentUserCan(
                      _vm.$permissions[`PERM_MAKE_${type}_REQUESTS`]
                    )},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}},[_vm._v(" "+_vm._s(_vm.$t(`REQUESTS.REQUEST_TYPE_${type}`))+" "),_c('span',[_c('i',{staticClass:"far fa-check"})])])}),1)]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.request_type}})],1),_c('div',{staticClass:"inspection-form-group width-1 has-button"},[_c('base-input',{attrs:{"label":`${_vm.$t('REQUESTS.VEHICLE_VIN')}`,"placeholder":_vm.$t('REQUESTS.VEHICLE_VIN')}},[_c('vehicle-api-by-vin-selector',{attrs:{"vin":_vm.request.vehicle_vin,"clearable":""},on:{"vehicleSelected":(vehicleData) => {
                    if (vehicleData.id) {
                      _vm.request.vehicle.id = `${vehicleData.id}`;
                      _vm.request.vehicle_vin = vehicleData.requested_vin;
                      _vm.request.vehicle_sku = vehicleData.sku;
                    } else {
                      _vm.request.vehicle_vin = vehicleData.requested_vin;
                    }
                    _vm.onFormChanged();
                  }}})],1),_c('base-button',{staticClass:"expand",attrs:{"icon":"","size":"sm"}},[_c('i',{staticClass:"far fa-expand"})]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.vehicle_vin}})],1),_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('REQUESTS.VEHICLE_SKU')}`,"placeholder":_vm.$t('REQUESTS.VEHICLE_SKU')},model:{value:(_vm.request.vehicle_sku),callback:function ($$v) {_vm.$set(_vm.request, "vehicle_sku", $$v)},expression:"request.vehicle_sku"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.vehicle_sku}})],1),_c('div',{staticClass:"inspection-form-group width-1 gallery"},[_c('gallery-selector',{attrs:{"label":_vm.$t('COMMON.PICTURES'),"defaultGallery":_vm.request.gallery,"ressource_name":"requests","ressource_id":0,"field":"gallery"},on:{"galleryChanged":(gallery_urls) => {
                  _vm.request.gallery = gallery_urls;
                  _vm.onFormChanged();
                }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.gallery}})],1),_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"type":"textarea","label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.request.excerpt),callback:function ($$v) {_vm.$set(_vm.request, "excerpt", $$v)},expression:"request.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TAGS')}`,"placeholder":`${_vm.$t('COMMON.TAGS')}`}},[_c('tags-selector',{attrs:{"tags":_vm.request.tags,"organization":_vm.request.organization.id},on:{"tagsChanged":(tags) => {
                    _vm.request.tags = tags;
                    _vm.onFormChanged();
                  }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tags}})],1),_c('div',{staticClass:"inspection-form-group width-1 next-button"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("REQUESTS.ADD_REQUEST"))+" ")])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }