<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ request.code }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("COMMON.TAGS") }}
        </dt>
        <dd class="col-sm-8">
          <tags :tags="request.tags" @tagsUpdated="requestUpdated" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CODE") }}</dt>
        <dd class="col-sm-8">
          {{ request.code }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.PRIORITY") }}</dt>
        <dd class="col-sm-8">
          <request-priority-badge :request="request" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
        <dd class="col-sm-8">
          <request-status-badge :request="request" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("REQUESTS.REQUEST_TYPE") }}</dt>
        <dd class="col-sm-8">
          {{ $t(`REQUESTS.REQUEST_TYPE_${request.request_type}`) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-8" v-html="request.excerpt"></dd>
      </dl>

      <dl class="row" v-if="!!request.vehicle">
        <dt class="col-sm-4">{{ $t("COMMON.VEHICLE") }}</dt>
        <dd class="col-sm-8">
          <object-link
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)"
            :object="request.vehicle"
          />
          <span v-else>{{ $objectDenomination(request.vehicle) }}</span>
        </dd>
      </dl>

      <dl class="row" v-if="request.responseObject">
        <dt class="col-sm-4">{{ $t("REQUESTS.RESPONSE_OBJECT") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="request.responseObject" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("REQUESTS.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="request.created_at">
          {{ $timeZoneDateFormat(request.created_at, "LLLL") }}
        </dd>
      </dl>
      <dl class="row" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)">
        <dt class="col-sm-4">{{ $t("REQUESTS.CREATOR") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="request.creator" />
        </dd>
      </dl>

      <dl class="row" v-if="request.approved_at">
        <dt class="col-sm-4">{{ $t("REQUESTS.APPROVED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ $timeZoneDateFormat(request.approved_at, "LLLL") }}
        </dd>
      </dl>
      <dl
        class="row"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          request.approvedBy
        "
      >
        <dt class="col-sm-4">{{ $t("REQUESTS.APPROVED_BY") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="request.approvedBy" />
        </dd>
      </dl>

      <dl class="row" v-if="request.assigned_at">
        <dt class="col-sm-4">{{ $t("REQUESTS.ASSIGNED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ $timeZoneDateFormat(request.assigned_at, "LLLL") }}
        </dd>
      </dl>
      <dl
        class="row"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          request.assignedBy
        "
      >
        <dt class="col-sm-4">{{ $t("REQUESTS.ASSIGNED_BY") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="request.assignedBy" />
        </dd>
      </dl>
      <dl
        class="row"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          request.assignedTo
        "
      >
        <dt class="col-sm-4">{{ $t("REQUESTS.ASSIGNED_TO") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="request.assignedTo" />
        </dd>
      </dl>

      <dl class="row" v-if="request.completed_at">
        <dt class="col-sm-4">
          {{
            request.status === REQUEST_STATUS_CANCELED
              ? $t("REQUESTS.CANCELED_AT")
              : $t("REQUESTS.COMPLETED_AT")
          }}
        </dt>
        <dd class="col-sm-8">
          {{ $timeZoneDateFormat(request.completed_at, "LLLL") }}
        </dd>
      </dl>
      <dl
        class="row"
        v-if="
          $currentUserCan($permissions.PERM_VIEW_ANY_USERS) &&
          request.completedBy
        "
      >
        <dt class="col-sm-4">
          {{
            request.status === REQUEST_STATUS_CANCELED
              ? $t("REQUESTS.CANCELED_BY")
              : $t("REQUESTS.COMPLETED_BY")
          }}
        </dt>
        <dd class="col-sm-8">
          <object-link :object="request.completedBy" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="request.allowedLocations" />
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="request.organization" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("REQUESTS.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="request.updated_at">
          {{ $timeZoneDateFormat(request.updated_at, "LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { REQUEST_STATUS_CANCELED } from "@/constants/requests";
import Tags from "@/components/Tags.vue";
import Organization from "@/components/Organization.vue";
import RequestStatusBadge from "./RequestStatusBadge.vue";
import RequestPriorityBadge from "./RequestPriorityBadge.vue";

export default {
  name: "request-view-global",

  components: {
    Tags,
    Organization,
    RequestStatusBadge,
    RequestPriorityBadge,
  },

  props: ["request"],

  data() {
    return {
      REQUEST_STATUS_CANCELED: REQUEST_STATUS_CANCELED,
    };
  },

  computed: {},

  created() {},

  methods: {
    requestUpdated() {
      this.$emit("requestUpdated", true);
    },
  },

  mounted() {},

  watch: {
    request(request) {},
  },
};
</script>
