import moment from "moment";
import { REQUEST_PRIORITY_MEDIUM } from "@/constants/requests";

export default {
  type: "requests",
  excerpt: "",
  request_type: "",
  priority: REQUEST_PRIORITY_MEDIUM,
  expiration_time: moment().add(30, "days").format("YYYY-MM-DD"),
  relationshipNames: [
    "organization",
    "customer",
    "allowedLocations",
    "tags",
    "vehicle",
  ],
  organization: {
    type: "organizations",
    id: null,
  },
  customer: {
    type: "customers",
    id: null,
  },
  creator: {
    type: "users",
    id: null,
  },
  vehicle: {
    type: "vehicles",
    id: null,
  },
  tags: [],
  allowedLocations: [],
};
