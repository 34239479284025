export const REQUEST_TYPE_INSPECTIONS = "INSPECTIONS";
export const REQUEST_TYPE_EVALUATIONS = "EVALUATIONS";
export const REQUEST_TYPE_REPARATIONS = "REPARATIONS";

export const REQUEST_STATUS_DRAFT = "DRAFT";
export const REQUEST_STATUS_APPROVED = "APPROVED";
export const REQUEST_STATUS_ASSIGNED = "ASSIGNED";
export const REQUEST_STATUS_COMPLETED = "COMPLETED";
export const REQUEST_STATUS_CANCELED = "CANCELED";

export const REQUEST_PRIORITY_LOW = 10;
export const REQUEST_PRIORITY_MEDIUM = 20;
export const REQUEST_PRIORITY_HIGH = 30;

export const requestStatusesOption = [
  REQUEST_STATUS_DRAFT,
  REQUEST_STATUS_APPROVED,
  REQUEST_STATUS_ASSIGNED,
  REQUEST_STATUS_COMPLETED,
  REQUEST_STATUS_CANCELED,
];

export const requestTypesOption = [
  REQUEST_TYPE_INSPECTIONS,
  REQUEST_TYPE_REPARATIONS,
  REQUEST_TYPE_EVALUATIONS,
];

export const requestPrioritiesOption = [
  REQUEST_PRIORITY_LOW,
  REQUEST_PRIORITY_MEDIUM,
  REQUEST_PRIORITY_HIGH,
];
