var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"tabs-wrapper"},[_c('div',{staticClass:"tab-pane-inner"},[_c('div',{staticClass:"inspection-form"},[_c('div',{staticClass:"inspection-form-group width-1_2"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('base-input',{attrs:{"disabled":!!_vm.request.id,"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"disabled":!!_vm.request.id,"organization":_vm.request.organization.id,"filterable":true,"showAll":false},on:{"organizationChanged":(organizationId) => {
                  _vm.request.organization.id = organizationId;
                  _vm.onFormChanged();
                }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.request.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.request.organization.id},on:{"locationsChanged":(locations) => {
                  _vm.request.allowedLocations = locations;
                  _vm.onFormChanged();
                }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.PRIORITY'),"placeholder":_vm.$t('COMMON.PRIORITY')}},[_c('el-select',{attrs:{"name":"Type"},on:{"change":(priority) => {
                  _vm.request.priority = priority;
                  _vm.onFormChanged();
                }},model:{value:(_vm.request.priority),callback:function ($$v) {_vm.$set(_vm.request, "priority", $$v)},expression:"request.priority"}},_vm._l((_vm.requestPrioritiesOption),function(priority){return _c('el-option',{key:priority,class:{
                  'priority-low': priority == _vm.REQUEST_PRIORITY_LOW,
                  'priority-medium': priority == _vm.REQUEST_PRIORITY_MEDIUM,
                  'priority-high': priority == _vm.REQUEST_PRIORITY_HIGH,
                },attrs:{"value":priority,"label":_vm.$t(`REQUESTS.REQUEST_PRIORITY_${priority}`)}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.priority}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.TYPE'),"placeholder":_vm.$t('COMMON.TYPE')}},[_c('el-select',{attrs:{"name":"Type","prepend-icon":"fas fa-calendar"},on:{"change":(request_type) => {
                  _vm.request.request_type = request_type;
                  _vm.onFormChanged();
                }},model:{value:(_vm.request.request_type),callback:function ($$v) {_vm.$set(_vm.request, "request_type", $$v)},expression:"request.request_type"}},_vm._l((_vm.requestTypesOption),function(type){return _c('el-option',{key:type,attrs:{"value":type,"label":_vm.$t(`REQUESTS.REQUEST_TYPE_${type}`)}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.request_type}})],1),_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CUSTOMER')}`,"placeholder":_vm.$t('COMMON.CUSTOMER'),"disabled":!!_vm.request.id}},[_c('customer-selector',{attrs:{"disabled":!!_vm.request.id,"customer":_vm.request.customer?.id,"filterable":true,"showAll":false,"filterOrganization":_vm.request.organization.id ? _vm.request.organization.id : null},on:{"customerChanged":(customerId) => {
                  _vm.request.customer.id = customerId;
                  _vm.onFormChanged();
                }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.customer}})],1),_c('div',{staticClass:"inspection-form-group width-1 has-button"},[_c('base-input',{attrs:{"label":`${_vm.$t('REQUESTS.VEHICLE_VIN')}`,"placeholder":_vm.$t('REQUESTS.VEHICLE_VIN')}},[_c('vehicle-api-by-vin-selector',{attrs:{"vin":_vm.request.vehicle_vin,"clearable":""},on:{"vehicleSelected":(vehicleData) => {
                  if (vehicleData.id) {
                    _vm.request.vehicle.id = `${vehicleData.id}`;
                    _vm.request.vehicle_vin = vehicleData.requested_vin;
                    _vm.request.vehicle_sku = vehicleData.sku;
                  } else {
                    _vm.request.vehicle_vin = vehicleData.requested_vin;
                  }
                  _vm.onFormChanged();
                }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.vehicle_vin}})],1),_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('REQUESTS.VEHICLE_SKU')}`,"placeholder":_vm.$t('REQUESTS.VEHICLE_SKU')},model:{value:(_vm.request.vehicle_sku),callback:function ($$v) {_vm.$set(_vm.request, "vehicle_sku", $$v)},expression:"request.vehicle_sku"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.vehicle_sku}})],1),_c('div',{staticClass:"inspection-form-group width-1 gallery mb-3"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PICTURES')}`,"placeholder":_vm.$t('COMMON.PICTURES')}},[_c('gallery-selector',{attrs:{"label":_vm.$t('COMMON.PICTURES'),"defaultGallery":_vm.request.gallery,"ressource_name":"requests","ressource_id":0,"field":"gallery"},on:{"galleryChanged":(gallery_urls) => {
                  _vm.request.gallery = gallery_urls;
                  _vm.onFormChanged();
                }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.gallery}})],1),_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('REQUESTS.EXPIRATION_TIME')} (*)`,"placeholder":_vm.$t('REQUESTS.EXPIRATION_TIME')}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
                allowInput: true,
                minDate: 'today',
                locale: _vm.$flatPickrLocale(),
              }},on:{"on-change":() => {
                  _vm.onFormChanged();
                }},model:{value:(_vm.request.expiration_time),callback:function ($$v) {_vm.$set(_vm.request, "expiration_time", $$v)},expression:"request.expiration_time"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.expiration_time}})],1),_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"type":"textarea","label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.request.excerpt),callback:function ($$v) {_vm.$set(_vm.request, "excerpt", $$v)},expression:"request.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TAGS')}`,"placeholder":`${_vm.$t('COMMON.TAGS')}`}},[_c('tags-selector',{attrs:{"tags":_vm.request.tags,"disabled":!_vm.request.organization.id,"organization":_vm.request.organization.id},on:{"tagsChanged":(tags) => {
                  _vm.request.tags = tags;
                  _vm.onFormChanged();
                }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tags}})],1),_c('div',{staticClass:"inspection-form-group width-1 next-button"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.request.id ? _vm.$t("REQUESTS.EDIT_REQUEST") : _vm.$t("REQUESTS.ADD_REQUEST"))+" ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }