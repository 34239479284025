<template>
  <div class="container-fluid">
    <request-form
      :loading="loading"
      :requestData="request"
      :formErrors="formErrors"
      @requestSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultRequest from "../defaultRequest";
import RequestForm from "../partials/RequestForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    RequestForm,
  },

  mixins: [alertLeave],

  data() {
    let requestData = cloneDeep(defaultRequest);
    requestData = this.$fillUserOrganizationData(requestData);
    requestData.vehicle_vin = this.$route.query.vehicle_vin;
    return {
      request: requestData,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(request) {
      this.loading = true;

      let requestData = cloneDeep(request);
      requestData = this.$fillUserOrganizationData(requestData);

      delete requestData.id;
      if (this.$route.query.vehicle_id) {
        requestData.vehicle = {
          type: "vehicles",
          id: this.$route.query.vehicle_id,
        };
      }
      try {
        await this.$store.dispatch("requests/add", requestData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REQUESTS.REQUEST_ADDED"),
        });
        const request = await this.$store.getters["requests/request"];
        this.$emit("onViewRequest", request, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
