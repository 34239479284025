<template>
  <div class="elite-tabs-wrapper-content">
    <div class="row" style="">
      <div class="col-4" v-for="(image, key) in request.gallery" :key="key">
        <img :src="image" class="argon-image mb-2" style="width: 100%" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "request-view-gallery",

  components: {},

  props: ["request"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    request(request) {},
  },
};
</script>
