<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <form v-if="showModal" class="" @submit.prevent="">
      <base-input
        :label="`${$t('COMMON.USER')} (*)`"
        :placeholder="$t('COMMON.USER')"
      >
        <user-selector
          :user="user"
          :filterable="true"
          :showAll="false"
          :filterOrganization="
            request.organization.id ? request.organization.id : null
          "
          @userChanged="
            (userId) => {
              user = userId;
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.user" />
    </form>

    <template slot="footer">
      <button
        type="button"
        class="btn btn-elite black"
        @click="closeAssignRequestModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
      <button
        type="submit"
        class="btn ml-auto btn-elite save"
        @click="assignRequest"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("REQUESTS.ASSIGN_REQUEST") }}
      </button>
    </template>
  </modal>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import UserSelector from "@/components/UserSelector.vue";

export default {
  name: "request-view-assign-request-form",

  components: {
    ValidationError,
    UserSelector,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["request", "showModal"],

  data() {
    return {
      user: this.request.assignedTo?.id,
      loading: false,
    };
  },

  computed: {},

  methods: {
    closeAssignRequestModal() {
      this.$emit("onCloseAssignRequestModal", true);
    },

    async assignRequest() {
      this.loading = true;
      try {
        await this.$store.dispatch("requests/assign", {
          requestId: this.request.id,
          data: { user: this.user },
        });
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REQUESTS.REQUEST_ASSIGNED"),
        });
        this.$emit("onRequestAssigned");
        this.closeAssignRequestModal();
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {
    request(request) {
      if (request) {
        this.user = request.assignedTo?.id;
      }
    },
  },
};
</script>

<style lang="scss">
.modal {
  .modal-content {
    .modal-body {
      form {
        span {
          width: 100%;
          display: block;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          .form-group {
            margin-bottom: 0;
            .form-control-label {
              margin: 0 0 10px 0;
            }
          }
        }
      }
    }
  }
}
</style>
