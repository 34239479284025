<template>
  <badge :type="getBadgeType()">
    {{ $t(`REQUESTS.REQUEST_PRIORITY_${request.priority}`) }}
  </badge>
</template>

<script>
import {
  REQUEST_PRIORITY_LOW,
  REQUEST_PRIORITY_MEDIUM,
  REQUEST_PRIORITY_HIGH,
} from "@/constants/requests";

export default {
  name: "request-priority-badge",

  components: {},

  mixins: [],

  props: ["request"],

  data() {
    return {};
  },

  computed: {},

  methods: {
    getBadgeType() {
      switch (this.request.priority) {
        case REQUEST_PRIORITY_LOW:
          return "low";
        case REQUEST_PRIORITY_MEDIUM:
          return "medium";
        case REQUEST_PRIORITY_HIGH:
          return "high";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {},
};
</script>
