<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <div class="tabs-wrapper">
      <div class="tab-pane-inner">
        <div class="inspection-form">
          <div class="inspection-form-group width-1_2">
            <base-input
              :disabled="!!request.id"
              :label="`${$t('COMMON.ORGANIZATION')} (*)`"
              :placeholder="$t('COMMON.ORGANIZATION')"
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
            >
              <organization-selector
                :disabled="!!request.id"
                :organization="request.organization.id"
                :filterable="true"
                :showAll="false"
                @organizationChanged="
                  (organizationId) => {
                    request.organization.id = organizationId;
                    onFormChanged();
                  }
                "
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.organization" />
          </div>
          <div class="inspection-form-group width-1_2">
            <base-input
              :label="`${$t('COMMON.LOCATION')}`"
              :placeholder="$t('COMMON.LOCATION')"
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
            >
              <locations-selector
                :locations="request.allowedLocations"
                :filterable="true"
                :showAll="false"
                :multiple="true"
                :organization="request.organization.id"
                @locationsChanged="
                  (locations) => {
                    request.allowedLocations = locations;
                    onFormChanged();
                  }
                "
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.location" />
          </div>
          <div class="inspection-form-group width-1_2">
            <base-input
              :label="$t('COMMON.PRIORITY')"
              :placeholder="$t('COMMON.PRIORITY')"
            >
              <el-select
                name="Type"
                v-model="request.priority"
                @change="
                  (priority) => {
                    request.priority = priority;
                    onFormChanged();
                  }
                "
              >
                <el-option
                  v-for="priority in requestPrioritiesOption"
                  :key="priority"
                  :value="priority"
                  :label="$t(`REQUESTS.REQUEST_PRIORITY_${priority}`)"
                  :class="{
                    'priority-low': priority == REQUEST_PRIORITY_LOW,
                    'priority-medium': priority == REQUEST_PRIORITY_MEDIUM,
                    'priority-high': priority == REQUEST_PRIORITY_HIGH,
                  }"
                />
              </el-select>
            </base-input>
            <validation-error :errors="apiValidationErrors.priority" />
          </div>
          <div class="inspection-form-group width-1_2">
            <base-input
              :label="$t('COMMON.TYPE')"
              :placeholder="$t('COMMON.TYPE')"
            >
              <el-select
                name="Type"
                v-model="request.request_type"
                prepend-icon="fas fa-calendar"
                @change="
                  (request_type) => {
                    request.request_type = request_type;
                    onFormChanged();
                  }
                "
              >
                <el-option
                  v-for="type in requestTypesOption"
                  :key="type"
                  :value="type"
                  :label="$t(`REQUESTS.REQUEST_TYPE_${type}`)"
                />
              </el-select>
            </base-input>
            <validation-error :errors="apiValidationErrors.request_type" />
          </div>
          <div class="inspection-form-group width-1">
            <base-input
              :label="`${$t('COMMON.CUSTOMER')}`"
              :placeholder="$t('COMMON.CUSTOMER')"
              :disabled="!!request.id"
            >
              <customer-selector
                :disabled="!!request.id"
                :customer="request.customer?.id"
                :filterable="true"
                :showAll="false"
                :filterOrganization="
                  request.organization.id ? request.organization.id : null
                "
                @customerChanged="
                  (customerId) => {
                    request.customer.id = customerId;
                    onFormChanged();
                  }
                "
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.customer" />
          </div>
          <div class="inspection-form-group width-1 has-button">
            <base-input
              :label="`${$t('REQUESTS.VEHICLE_VIN')}`"
              :placeholder="$t('REQUESTS.VEHICLE_VIN')"
            >
              <vehicle-api-by-vin-selector
                :vin="request.vehicle_vin"
                @vehicleSelected="
                  (vehicleData) => {
                    if (vehicleData.id) {
                      request.vehicle.id = `${vehicleData.id}`;
                      request.vehicle_vin = vehicleData.requested_vin;
                      request.vehicle_sku = vehicleData.sku;
                    } else {
                      request.vehicle_vin = vehicleData.requested_vin;
                    }
                    onFormChanged();
                  }
                "
                clearable
              />
            </base-input>
            <!-- <base-button class="expand" icon size="sm">
              <i class="far fa-expand"></i>
            </base-button> -->
            <validation-error :errors="apiValidationErrors.vehicle_vin" />
          </div>
          <div class="inspection-form-group width-1">
            <base-input
              :label="`${$t('REQUESTS.VEHICLE_SKU')}`"
              :placeholder="$t('REQUESTS.VEHICLE_SKU')"
              v-model="request.vehicle_sku"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.vehicle_sku" />
          </div>

          <div class="inspection-form-group width-1 gallery mb-3">
            <base-input
              :label="`${$t('COMMON.PICTURES')}`"
              :placeholder="$t('COMMON.PICTURES')"
            >
              <gallery-selector
                :label="$t('COMMON.PICTURES')"
                :defaultGallery="request.gallery"
                ressource_name="requests"
                :ressource_id="0"
                field="gallery"
                @galleryChanged="
                  (gallery_urls) => {
                    request.gallery = gallery_urls;
                    onFormChanged();
                  }
                "
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.gallery" />
          </div>

          <div class="inspection-form-group width-1">
            <base-input
              :label="`${$t('REQUESTS.EXPIRATION_TIME')} (*)`"
              :placeholder="$t('REQUESTS.EXPIRATION_TIME')"
            >
              <flat-picker
                :config="{
                  allowInput: true,
                  minDate: 'today',
                  locale: $flatPickrLocale(),
                }"
                class="form-control datepicker"
                v-model="request.expiration_time"
                @on-change="
                  () => {
                    onFormChanged();
                  }
                "
              >
              </flat-picker>
            </base-input>
            <validation-error :errors="apiValidationErrors.expiration_time" />
          </div>
          <div class="inspection-form-group width-1">
            <base-input
              type="textarea"
              :label="$t('COMMON.EXCERPT')"
              :placeholder="$t('COMMON.EXCERPT')"
            >
              <html-editor v-model="request.excerpt" @change="onFormChanged()">
              </html-editor>
            </base-input>
            <validation-error :errors="apiValidationErrors.excerpt" />
          </div>
          <div class="inspection-form-group width-1">
            <base-input
              :label="`${$t('COMMON.TAGS')}`"
              :placeholder="`${$t('COMMON.TAGS')}`"
            >
              <tags-selector
                :tags="request.tags"
                @tagsChanged="
                  (tags) => {
                    request.tags = tags;
                    onFormChanged();
                  }
                "
                :disabled="!request.organization.id"
                :organization="request.organization.id"
              />
            </base-input>
            <validation-error :errors="apiValidationErrors.tags" />
          </div>
          <div class="inspection-form-group width-1 next-button">
            <base-button
              type="button"
              class="btn btn-sm elite-submit"
              native-type="submit"
              :disabled="loading"
            >
              <i class="fas fa-spinner fa-spin" v-if="loading"></i>
              {{
                request.id
                  ? $t("REQUESTS.EDIT_REQUEST")
                  : $t("REQUESTS.ADD_REQUEST")
              }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import moment from "moment";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import {
  requestTypesOption,
  requestPrioritiesOption,
  REQUEST_PRIORITY_LOW,
  REQUEST_PRIORITY_MEDIUM,
  REQUEST_PRIORITY_HIGH,
} from "@/constants/requests";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import VehicleApiByVinSelector from "@/components/VehicleApiByVinSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    flatPicker,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    CustomerSelector,
    LocationsSelector,
    TagsSelector,
    VehicleApiByVinSelector,
    GallerySelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["requestData", "formErrors", "loading"],

  data() {
    return {
      request: { ...this.requestData },
      permissions: [],
      permissionsModel: {},
      models: models,
      requestTypesOption: requestTypesOption,
      requestPrioritiesOption: requestPrioritiesOption,
      REQUEST_PRIORITY_LOW: REQUEST_PRIORITY_LOW,
      REQUEST_PRIORITY_MEDIUM: REQUEST_PRIORITY_MEDIUM,
      REQUEST_PRIORITY_HIGH: REQUEST_PRIORITY_HIGH,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      const requestData = cloneDeep(this.request);
      requestData.expiration_time = moment(
        requestData.expiration_time
      ).toISOString();
      if (requestData.customer) {
        if (!requestData.customer.id) {
          delete requestData.customer;
        }
      }
      if (requestData.vehicle) {
        if (!requestData.vehicle.id) {
          delete requestData.vehicle;
        }
      }
      this.$emit("requestSubmitted", requestData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    requestData(requestData) {
      if (requestData) {
        this.request = {
          ...this.request,
          ...cloneDeep(requestData),
        };
      }
    },
  },
};
</script>
