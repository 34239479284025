<template>
  <div class="container-fluid">
    <form ref="profile_form" @submit.prevent="handleSubmit">
      <div class="tabs-wrapper">
        <div class="tab-pane-inner">
          <div class="inspection-form">
            <div class="inspection-form-group width-1_2">
              <base-input
                :label="`${$t('COMMON.ORGANIZATION')} (*)`"
                :placeholder="$t('COMMON.ORGANIZATION')"
                v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
              >
                <organization-selector
                  :organization="request.organization.id"
                  :filterable="true"
                  :showAll="false"
                  @organizationChanged="
                    (organizationId) => {
                      request.organization.id = organizationId;
                      onFormChanged();
                    }
                  "
                />
              </base-input>
              <validation-error :errors="apiValidationErrors.organization" />
            </div>
            <div class="inspection-form-group width-1_2">
              <base-input
                :label="`${$t('COMMON.LOCATION')}`"
                :placeholder="$t('COMMON.LOCATION')"
                v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
              >
                <locations-selector
                  :locations="request.allowedLocations"
                  :filterable="true"
                  :showAll="false"
                  :multiple="true"
                  :organization="request.organization.id"
                  @locationsChanged="
                    (locations) => {
                      request.allowedLocations = locations;
                      onFormChanged();
                    }
                  "
                />
              </base-input>
              <validation-error :errors="apiValidationErrors.location" />
            </div>
            <div class="inspection-form-group width-1">
              <base-input
                :label="$t('COMMON.PRIORITY')"
                :placeholder="$t('COMMON.PRIORITY')"
              >
                <div class="radio-group">
                  <base-radio
                    v-for="priority in requestPrioritiesOption"
                    :key="priority"
                    :name="`${priority}`"
                    class="mb-3"
                    :class="{
                      'priority-low': priority == REQUEST_PRIORITY_LOW,
                      'priority-medium': priority == REQUEST_PRIORITY_MEDIUM,
                      'priority-high': priority == REQUEST_PRIORITY_HIGH,
                    }"
                    v-model="selectedPriority"
                  >
                    {{ $t(`REQUESTS.REQUEST_PRIORITY_${priority}`) }}
                    <span>
                      <i class="far fa-check"></i>
                    </span>
                  </base-radio>
                </div>
              </base-input>
              <validation-error :errors="apiValidationErrors.priority" />
            </div>
            <div class="inspection-form-group width-1_2">
              <base-input
                :label="$t('COMMON.TYPE')"
                :placeholder="$t('COMMON.TYPE')"
              >
                <div class="radio-group">
                  <base-radio
                    v-for="type in requestTypesOption"
                    :key="type"
                    :name="`${type}`"
                    class="mb-3"
                    v-model="selectedType"
                    :disabled="
                      !$currentUserCan(
                        $permissions[`PERM_MAKE_${type}_REQUESTS`]
                      )
                    "
                  >
                    {{ $t(`REQUESTS.REQUEST_TYPE_${type}`) }}
                    <span>
                      <i class="far fa-check"></i>
                    </span>
                  </base-radio>
                </div>
              </base-input>
              <validation-error :errors="apiValidationErrors.request_type" />
            </div>
            <div class="inspection-form-group width-1 has-button">
              <base-input
                :label="`${$t('REQUESTS.VEHICLE_VIN')}`"
                :placeholder="$t('REQUESTS.VEHICLE_VIN')"
              >
                <vehicle-api-by-vin-selector
                  :vin="request.vehicle_vin"
                  @vehicleSelected="
                    (vehicleData) => {
                      if (vehicleData.id) {
                        request.vehicle.id = `${vehicleData.id}`;
                        request.vehicle_vin = vehicleData.requested_vin;
                        request.vehicle_sku = vehicleData.sku;
                      } else {
                        request.vehicle_vin = vehicleData.requested_vin;
                      }
                      onFormChanged();
                    }
                  "
                  clearable
                />
              </base-input>
              <base-button class="expand" icon size="sm">
                <i class="far fa-expand"></i>
              </base-button>
              <validation-error :errors="apiValidationErrors.vehicle_vin" />
            </div>
            <div class="inspection-form-group width-1">
              <base-input
                :label="`${$t('REQUESTS.VEHICLE_SKU')}`"
                :placeholder="$t('REQUESTS.VEHICLE_SKU')"
                v-model="request.vehicle_sku"
              >
              </base-input>
              <validation-error :errors="apiValidationErrors.vehicle_sku" />
            </div>

            <div class="inspection-form-group width-1 gallery">
              <gallery-selector
                :label="$t('COMMON.PICTURES')"
                :defaultGallery="request.gallery"
                ressource_name="requests"
                :ressource_id="0"
                field="gallery"
                @galleryChanged="
                  (gallery_urls) => {
                    request.gallery = gallery_urls;
                    onFormChanged();
                  }
                "
              />
              <validation-error :errors="apiValidationErrors.gallery" />
            </div>

            <div class="inspection-form-group width-1">
              <base-input
                type="textarea"
                :label="$t('COMMON.EXCERPT')"
                :placeholder="$t('COMMON.EXCERPT')"
              >
                <html-editor
                  v-model="request.excerpt"
                  @change="onFormChanged()"
                >
                </html-editor>
              </base-input>
              <validation-error :errors="apiValidationErrors.excerpt" />
            </div>
            <div class="inspection-form-group width-1">
              <base-input
                :label="`${$t('COMMON.TAGS')}`"
                :placeholder="`${$t('COMMON.TAGS')}`"
              >
                <tags-selector
                  :tags="request.tags"
                  @tagsChanged="
                    (tags) => {
                      request.tags = tags;
                      onFormChanged();
                    }
                  "
                  :organization="request.organization.id"
                />
              </base-input>
              <validation-error :errors="apiValidationErrors.tags" />
            </div>
            <div class="inspection-form-group width-1 next-button">
              <base-button
                type="button"
                class="btn btn-sm elite-submit"
                native-type="submit"
                :disabled="loading"
              >
                <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                {{ $t("REQUESTS.ADD_REQUEST") }}
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import moment from "moment";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultRequest from "../defaultRequest";
import "flatpickr/dist/flatpickr.css";
import {
  requestTypesOption,
  requestPrioritiesOption,
  REQUEST_PRIORITY_LOW,
  REQUEST_PRIORITY_MEDIUM,
  REQUEST_PRIORITY_HIGH,
} from "@/constants/requests";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import VehicleApiByVinSelector from "@/components/VehicleApiByVinSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationsSelector,
    TagsSelector,
    VehicleApiByVinSelector,
    GallerySelector,
  },

  mixins: [alertLeave, formMixin],

  data() {
    let requestData = cloneDeep(defaultRequest);
    requestData = this.$fillUserOrganizationData(requestData);
    return {
      formErrors: null,
      loading: false,
      request: requestData,
      requestTypesOption: requestTypesOption,
      requestPrioritiesOption: requestPrioritiesOption,
      REQUEST_PRIORITY_LOW: REQUEST_PRIORITY_LOW,
      REQUEST_PRIORITY_MEDIUM: REQUEST_PRIORITY_MEDIUM,
      REQUEST_PRIORITY_HIGH: REQUEST_PRIORITY_HIGH,
      selectedPriority: null,
      selectedType: null,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      this.loading = true;
      let requestData = cloneDeep(this.request);
      requestData = this.$fillUserOrganizationData(requestData);

      delete requestData.id;
      if (this.$route.query.vehicle_id) {
        requestData.vehicle = {
          type: "vehicles",
          id: this.$route.query.vehicle_id,
        };
      }

      if (requestData.vehicle) {
        if (!requestData.vehicle.id) {
          delete requestData.vehicle;
        }
      }
      if (requestData.customer) {
        if (!requestData.customer.id) {
          delete requestData.customer;
        }
      }
      requestData.priority = this.selectedPriority;
      requestData.request_type = this.selectedType;
      requestData.expiration_time = moment(
        requestData.expiration_time
      ).toISOString();
      try {
        await this.$store.dispatch("requests/add", requestData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("REQUESTS.REQUEST_ADDED"),
        });
        const request = await this.$store.getters["requests/request"];
        this.$emit("onViewRequest", request, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },

    onFormChanged() {
      this.alertLeave = true;
    },
  },
};
</script>
